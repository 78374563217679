<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
    <mat-toolbar style="color: rgb(255, 255, 255);text-align: center;">Menu</mat-toolbar>
      <div>
        <button class="button-especial" routerLink="paginas/postcosecha" *ngIf="isAdmin">
          <span>Finca</span>
        </button>
        <button class="button-especial" routerLink="paginas/variedad" *ngIf="isAdmin">
          <span>Variedad</span>
        </button>
        <button class="button-especial" routerLink="paginas/variedad-spray" *ngIf="isAdmin">
          <span>Variedad Spray</span>
        </button>
        <button class="button-especial" routerLink="paginas/usuario" *ngIf="isAdmin">
          <span>Usuarios</span>
        </button>
        <button class="button-especial" routerLink="paginas/usuario-postcosecha" *ngIf="isAdmin">
          <span>Asignar Fincas a Usuarios</span>
        </button>
        <button class="button-especial" routerLink="paginas/postcosecha-variedad" *ngIf="isAdmin">
          <span>Asignar Variedades a Fincas</span>
        </button>
        <button class="button-especial" routerLink="paginas/postcosecha-variedad-spray" *ngIf="isAdmin">
          <span>Asignar Variedades Spray a Fincas</span>
        </button>
        <button class="button-especial" (click)="toggleEvaluacionRamos()" *ngIf="isAdmin">
          <span>Evaluación Ramos</span>
        </button>

        <div *ngIf="mostrarEvaluacionRamos">
          <button class="button-especial" routerLink="paginas/proceso-revision-ramos">
            <span>Datos generales Revision Ramos</span>
          </button>
          <button class="button-especial" routerLink="paginas/bonchadora-presentacion-bonch">
            <span>Bonchadora Presentacion Bonch</span>
          </button>
          <button class="button-especial" routerLink="paginas/bonchadora-boton">
            <span>Bonchadora Botón</span>
          </button>
          <button class="button-especial" routerLink="paginas/clasificacion-boton">
            <span>Clasificación Botón</span>
          </button>
          <button class="button-especial" routerLink="paginas/clasificacion-enfermedad">
            <span>Clasificación Enfermedad</span>
          </button>
          <button class="button-especial" routerLink="paginas/clasificacion-plaga">
            <span>Clasificación Plaga</span>
          </button>
          <button class="button-especial" routerLink="paginas/clasificacion-tallo">
            <span>Clasificación Tallo</span>
          </button>
          <button class="button-especial" routerLink="paginas/clasificacion-follaje">
            <span>Clasificación Follaje</span>
          </button>
          <button class="button-especial" routerLink="paginas/mesa-corte">
            <span>Mesa Corte</span>
          </button>
          <button class="button-especial" routerLink="paginas/resultado-revision-ramos">
            <span>Resultado Revision Ramos</span>
          </button>
        </div>


      </div>

    <mat-nav-list style="background-color: rgba(255, 255, 255, 0.342);margin: 30px;border-radius: 18px;">
      <a mat-list-item (click)="logOut($event)" style="text-align: center;"><mat-icon>logout</mat-icon>Salir</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar *ngIf="isLogin | async" color="primary" style="background-color: black; color: white">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>High Control Group</span>
    </mat-toolbar>
    <div>
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
