import { Component, OnInit } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { map, share } from "rxjs/operators";
import { AuthService } from "../paginas/login/auth.service";

@Component({
  selector: "app-main-nav",
  templateUrl: "./main-nav.component.html",
  styleUrls: ["./main-nav.component.css"],
})
export class MainNavComponent implements OnInit {
  isLogin: Observable<boolean>;
  isAdmin = false;
  mostrarEvaluacionRamos: boolean = false; // Inicializa como falso
  isAgencia = false;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      share()
    );
    toggleEvaluacionRamos(): void {
      this.mostrarEvaluacionRamos = !this.mostrarEvaluacionRamos; // Cambia el estado de visibilidad
    }
  constructor(
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.authService.login();
    this.isLogin = this.authService.isLoggedIn;
    if (
      localStorage.getItem("id") == "1" ||
      localStorage.getItem("id") == "13" ||
      localStorage.getItem("id") == "14" ||
      localStorage.getItem("id") == "15"
    ) {
      this.isAdmin = true;
    }
  }

  logOut(event: Event) {
    event.preventDefault();
    this.authService.logout();
    event.stopImmediatePropagation();
  }
}
